<template>
  <div>
    <div class="container">
      <div class="login">
        <div class="login-div">
          <img :src="require('@/assets/login/logoWithTitle.png')" />
        </div>
  
        <div class="login-content">
          <div class="login-with-email">
            <div class="input-div">
              <input type="text" v-model="loginEmail" placeholder="Email"/>
            </div>
  
            <div class="input-div">
              <input type="password" v-model="loginPassword" placeholder="密碼"/>
            </div>
  
            <p class="forgot-psw">
              <a href="#" @click.prevent="showForgotPasswordModal">忘記密碼?</a>
            </p>
  
            <!-- <div class="text-center pt-2">
              <button @click="emailLoginClicked">登入</button>
            </div> -->
          </div>
  
          <div class="login-with-sns">
  
            <p class="sns-desc">
              <span>或以社群帳號快速登入</span>
            </p>
  
            <div class="sns-logins">
              <a :href="`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${lineClientId}&redirect_uri=${callbackUri}&disable_ios_auto_login=true&bot_prompt=aggressive&scope=profile%20openid%20email&state=linelogin`" class="btn btn-wc btn-line mb-2">
                <img :src="require('@/assets/login/line.png')" />
                <!-- <span>以 LINE 帳號繼續</span> -->
              </a>
    
              <a :href="`https://www.facebook.com/v13.0/dialog/oauth?client_id=${fbClientId}&redirect_uri=${callbackUri}&scope=email,public_profile&state=fblogin`" class="btn btn-wc btn-fb mb-2">
                <img :src="require('@/assets/login/fb.png')" />
                <!-- <span>以 Facebook 帳號繼續</span> -->
              </a>
    
              <div ref="googleLoginBtn" id="buttonDiv"></div>
            </div>
  
            <p class="register-msg">
              還沒有帳號嗎？
              <a href="#" @click.prevent="showRegisterModal">註冊</a>
            </p>
  
            <p class="agree-msg mb-3">
              繼續使用代表你已詳閱並同意<br/>長腳衣櫥的<a href="#" @click.prevent="showPolicyModal">《隱私權聲明》</a>
            </p>
  
            <div class="text-center">
              <a href="#" v-if="!isInStandaloneMode" class="btn btn-desc" @click.prevent="toInstallPage">
                下載安裝<img :src="require('@/assets/login/logo_mini.png')" />長腳衣櫥
              </a>
            </div>
  
          </div>
        </div>
  
      </div>
    </div>

    <div class="bottom-sticky-login-div">
      <div class="container">
        <b-button variant="primary" class="w-100" block @click="emailLoginClicked">
          <span>登入</span>
        </b-button>
      </div>
    </div>

    <transition name="fade">
			<div v-if="showDialog">
				<div class="dialog-mask" @click="hideDialog">
				</div>

				<div class="dialog-div dialog-ios" v-if="showDescIOS">
					<h3>安裝App</h3>
					<p>ios 用戶請以 Safari 開啟本頁面</p>
					<p>按下瀏覽器中的 <img :src="require('@/assets/login/share.png')"/></p>
					<p>選擇 <span>加入主畫面</span> <img :src="require('@/assets/login/Add_square_light.png')"/></p>
					<p class="mb-0">點選 <span>新增</span> 即完成安裝</p>
				</div>

				<div class="dialog-div dialog-android" v-if="showDescAndroid">
					<h3>安裝App</h3>
					<p>Android 用戶請以 Chrome 開啟本頁面</p>
					<p>按下瀏覽器中的 <img :src="require('@/assets/login/options-vertical.png')"/></p>
					<p class="mb-5">選擇 <span>安裝應用程式</span> 即完成安裝</p>

					<a href="/closet/install_desc_IOS" target="_blank" >ios 用戶請點此</a>
				</div>
			</div>
		</transition>

    <transition name="fade">
      <RegisterModal v-if="isRegisterModalShow" @close="isRegisterModalShow = false"></RegisterModal>
    </transition>

    <transition name="fade">
      <ForgotPswdModal v-if="isForgotPasswordModalShow" @close="isForgotPasswordModalShow = false"></ForgotPswdModal>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import RegisterModal from '@/components/login/RegisterModal.vue';
import ForgotPswdModal from '@/components/login/ForgotPswdModal.vue';

export default {
  name: 'Login',
  data() {
    return {
      showDescIOS: false,
			showDescAndroid: false,
      loginEmail: '',
      loginPassword: '',
      isRegisterModalShow: false,
      isForgotPasswordModalShow: false,
    };
  },
  props: {
  },
  components: {
    RegisterModal,
    ForgotPswdModal,
  },
  computed: {
    ...mapState(['lineClientId', 'fbClientId']),
    ...mapGetters(['hasToken']),
    callbackUri() {
      return window.location.origin + window.location.pathname;
    },
    isInStandaloneMode: {
			get() {
				const mqStandAlone = '(display-mode: fullscreen)';
				// const mqStandAlone = '(display-mode: standalone)';
				return (window.navigator.standalone || window.matchMedia(mqStandAlone).matches);
				// return window.matchMedia('(display-mode: standalone)').matches;
			},
		},
		isIOS: {
			get() {
				const ua = window.navigator.userAgent.toLowerCase();
				console.log(ua);
				return (ua.indexOf('iphone') !== -1) || (ua.indexOf('ipad') !== -1);
			}
		},
		showDialog() {
			return this.showDescIOS || this.showDescAndroid;
		},
	},
  watch: {
  },
  mounted() {
    console.log(this.$route.query.code);
    if (this.hasToken) {
      this.$router.push({name: 'Home'});
    } else {
      const code = this.$route.query.code;
      const state = this.$route.query.state;

      if (code && state === 'linelogin') {
        this.loginByLineCode(code);
      } else if (code && state === 'fblogin') {
        this.loginByFbCode(code);
      }

      setTimeout(() => {
        this.initGoogleLogin();
      }, 100);
      setTimeout(() => {
				if (!this.isInStandaloneMode) {
					this.showDescDialog();
				}
			}, 1000);
    }
	},
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async loginByLineCode(code) {
      this.appendComponentBusy('LINELOGIN');
      try {
        const callbackUri = this.callbackUri;
        const {token} = await this.$store.dispatch('api/postLineLoginPromise', {code, callbackUri});
        await this.$store.dispatch('login', token);
        await this.$router.push({name: 'Home'});
      } catch(e) {
        console.error(e);
        this.clearQuery();
        // this.$store.dispatch('logout');
      } finally {
        this.clearComponentBusy('LINELOGIN');
      }
    },
    async loginByFbCode(code) {
      this.appendComponentBusy('FBLOGIN');
      try {
        const callbackUri = this.callbackUri;
        const {token} = await this.$store.dispatch('api/postFbLoginPromise', {code, callbackUri});
        await this.$store.dispatch('login', token);
        await this.$router.push({name: 'Home'});
      } catch(e) {
        console.error(e);
        this.clearQuery();
        // this.$store.dispatch('logout');
      } finally {
        this.clearComponentBusy('FBLOGIN');
      }
    },
    async loginByGoogleCredential(credential) {
      this.appendComponentBusy('GOOGLELOGIN');
      try {
        const {token} = await this.$store.dispatch('api/postGoogleLoginPromise', {credential});
        await this.$store.dispatch('login', token);
        await this.$router.push({name: 'Home'});
      } catch(e) {
        console.error(e);
        this.clearQuery();
        // this.$store.dispatch('logout');
      } finally {
        this.clearComponentBusy('GOOGLELOGIN');
      }
    },
    async emailLoginClicked() {
      this.appendComponentBusy('EMAILLOGIN');
      try {
        const {token} = await this.$store.dispatch('api/postEmailLoginPromise', {
          email: this.loginEmail,
          password: this.loginPassword,
        });
        await this.$store.dispatch('login', token);
        await this.$router.push({name: 'Home'});
      } catch(e) {
        if (e.toString() === 'Error: 找不到會員') {
          this.appendErrorMsg('登入失敗，請檢查登入資訊是否正確');
        }
        console.error(e);
        this.clearQuery();
        // this.$store.dispatch('logout');
      } finally {
        this.clearComponentBusy('EMAILLOGIN');
      }
    },
    clearQuery() {
      window.history.pushState({}, null, window.location.pathname);
    },
    toInstallPage() {
			if (this.isIOS) {
				window.open('/closet/install_desc_IOS', '_blank');
			} else {
				window.open('/closet/install_desc_android', '_blank');
			}
		},
    initGoogleLogin() {
			window.google.accounts.id.initialize({
        client_id: "219030855174-eb1an4vjkcjdou8rqd4bu2vlj7r3jrv8.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
				// login_uri: 'https://walkingcloset.network/closet/login_google',
				// ux_mode: "redirect"
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { type: 'icon', theme: "outline", size: "large" }  // customization attributes
      );
      // window.google.accounts.id.prompt();
		},
		handleCredentialResponse(response) {
      // console.log("Encoded JWT ID token: " + response.credential);
      this.loginByGoogleCredential(response.credential);
    },
    changeToIOS() {
			this.showDescIOS = true;
			this.showDescAndroid = false;
		},
		hideDialog() {
			this.showDescIOS = false;
			this.showDescAndroid = false;
		},
		showDescDialog() {
			if (this.isIOS) {
				this.showDescIOS = true;
			} else {
				this.showDescAndroid = true;
			}
		},
    showPolicyModal() {
      this.$store.dispatch('setPolicyShow', true);
    },
    showRegisterModal() {
      this.isRegisterModalShow = true;
    },
    showForgotPasswordModal() {
      this.isForgotPasswordModalShow = true;
    }
  }
}
</script>


<style lang="scss" scoped>
@import "src/assets/scss/basic";

  .login {
    padding: 3.5rem 0rem 3.5rem;
    .login-div {
      text-align: center;
      margin-bottom: 1.3rem;
      >img {
        width: 40%;
      }
    }

    .login-content {
      padding-bottom: 4rem;
      .login-with-email {
        padding: 0 1rem;
        margin-bottom: 2rem;
        .input-div {
          input {
            border: none;
            padding: .5rem 1rem;
            background-color: #eeeeee;
            // color: var(--text-color-remark);
            border-radius: 0.5rem;
            width: 100%;
            border-radius: .5rem;
            ::placeholder {
              color: var(--text-color-remark);
              color: red;
            }
          }
          &:not(:first-child) {
            margin-top: 1.62rem;
          }
        }
        .forgot-psw {
          margin-bottom: 0;
          margin-top: .2rem;
          font-size: .8rem;
        }
        button {
          padding: 0.5rem 2.5rem;
          display: inline-block;
          border: none;
          border-radius: 1rem;
          background: var(--brand-color-primary);
          color: #fff;
        }
      }

      .login-with-sns {
        .sns-logins {
          display: flex;
          justify-content: center;
          padding: 2rem 1rem 0;
          column-gap: 1rem;
        }
        .sns-desc {
          position: relative;
          width: 100%;
          text-align: center;
          >span {
            position: relative;
            color: var(--text-color-main);
            font-size: .8rem;
            background-color: #ffffff;
            padding: 0 .25rem;
          }
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            top: 50%;
            border-top: solid 1px var(--border-color-main);
          }
        }

        .btn {
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.btn-wc {
            // font-weight: bold;
            // width: 100%;
            // border-radius: .5rem;
            // padding: .4rem 1rem .5em 1rem;
            // position: relative;
            >img {
              // position: absolute;
              // left: .5rem;
              // top: 50%;
              // transform: translate(0, -50%);
              // height: 24px;
            }
          }
          &.btn-line {
            background-color: #06C755;
            color: #fff;
            // text-align: left;
            // padding-left: 3rem;
            >img {
              height: 36px;
            }
          }

          &.btn-fb {
            background-color: #4676ED;
            color: #fff;
            // text-align: left;
            // padding-left: 3rem;
            >img {
              // left: .8rem;
              height: 24px;
            }
          }

          &.btn-desc {
            border-color: #F2C31C;
            width: 100%;
            display: block;
            border-radius: 999px;
            color: #F2C31C;
            letter-spacing: .15em;
            >img {
              height: 1em;
              display: inline-block;
              margin: 0 .2rem;
              position: relative;
              top: -0.2em;
            }
          }
        }

        .register-msg {
          text-align: center;
          color: var(--text-color-main);
          font-size: .8rem;
          margin-top: 1.5rem;
          margin-bottom: 0;
          >a {
            color: var(--text-color-main);
            text-decoration: underline;
          }
        }

        .agree-msg {
          margin-top: 1.1rem;
          text-align: center;
          color: var(--text-color-main);
          font-size: .8rem;
          >a {
            color: var(--text-color-main);
            text-decoration: underline;
          }
        }
      }
    }
  }

  .bottom-sticky-login-div {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    padding: 1.5rem 1.5rem 2rem;
    box-shadow: 0 0 4px #0004;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    button {
      // padding: .8rem 1.5rem;
      // width: 100%;
      // display: block;
      // border: none;
      // border-radius: 1rem;
      // background: var(--brand-color-primary);
      // color: #fff;
    }
  }

	.fade-enter-active, .fade-leave-active {
    transition: opacity .4s ease;
	}

	.fade-enter, .fade-leave-to {
    opacity: 0;
	}

	.fade-enter-to, .fade-leave {
    opacity: 1;
	}

	.dialog-mask {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #0006;

	}

	.dialog-div {
		position: fixed;
		left: 15%;
		width: 70%;
		border-radius: .5rem;
		padding: 20px 24px;
		background-color: #fff;
		color: #4D4D4D;
		letter-spacing: .1em;
	}

	.dialog-div>h3 {
		font-size: 1rem;
		font-weight: 1000;
		margin-bottom: 1rem;
	}

	.dialog-div>p {
		line-height: 1.5em;
		font-size: .8rem;
		margin-bottom: 1em;
	}

	.dialog-div>p>img {
		position: absolute;
		height: 1.5em;
		display: inline-block;
		padding-left: .5rem;
	}

	.dialog-div>p>span {
		color: #F2C31C;
	}

	.dialog-div>a {
		position: absolute;
		right: 1rem;
		bottom: 1rem;
		color: #4d4d4d;
		text-decoration: underline;
	}

	.dialog-div.dialog-ios {
		bottom: 2rem;
	}

	.dialog-div.dialog-ios::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, 0);

		width: 0;
		height: 0;
		border-style: solid;
		border-width: 16px 12px 0 12px;
		border-color: #ffffff transparent transparent transparent;
	}

	.dialog-div.dialog-android {
		top: 50%;
		transform: translate(0, -50%);
	}
</style>
