<template>
  <FullScreenModal title="註冊" @back="backClicked">
    <div class="register">

      
      <div class="register-content">
        <div class="register-with-email">
          <h2>以 Email 創立帳號</h2>
          <div class="input-div">
            <input type="text" v-model="registerEmail" placeholder="輸入Email"/>
          </div>

          <!-- <div class="text-center pt-3">
            <button @click="registerClicked">註冊</button>
          </div> -->
        </div>

        <div class="login-with-sns">

          <p class="sns-desc">
            <span>或以社群帳號快速註冊</span>
          </p>

          <div class="sns-logins">
            <a :href="`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${lineClientId}&redirect_uri=${callbackUri}&disable_ios_auto_login=true&bot_prompt=aggressive&scope=profile%20openid%20email&state=linelogin`" class="btn btn-wc btn-line mb-2">
              <img :src="require('@/assets/login/line.png')" />
              <!-- <span>以 LINE 帳號繼續</span> -->
            </a>
  
            <a :href="`https://www.facebook.com/v13.0/dialog/oauth?client_id=${fbClientId}&redirect_uri=${callbackUri}&scope=email,public_profile&state=fblogin`" class="btn btn-wc btn-fb mb-2">
              <img :src="require('@/assets/login/fb.png')" />
              <!-- <span>以 Facebook 帳號繼續</span> -->
            </a>
  
            <div ref="googleLoginBtn" id="buttonRegDiv"></div>
          </div>

          <p class="register-msg">
            已經是會員了嗎?
            <a href="#" @click="loginClicked">登入</a>
          </p>

          <p class="agree-msg mb-3">
            繼續使用代表你已詳閱並同意<br/>長腳衣櫥的<a href="#" @click.prevent="showPolicyModal">《隱私權聲明》</a>
          </p>

        </div>

      </div>
      
      <div class="bottom-sticky-login-div">
        <button @click="registerClicked">註冊</button>
      </div>
		</div>
  </FullScreenModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';

export default {
  name: 'RegisterModal',
  data() {
    return {
      registerEmail: '',
    };
  },
  components: {
    FullScreenModal,
  },
  props: {},
  beforeDestroy() {},
  async mounted() {
    setTimeout(() => {
      this.initGoogleLogin();
    }, 100);
  },
  computed: {
    ...mapState(['lineClientId', 'fbClientId']),
    ...mapGetters(['hasToken']),
    callbackUri() {
      return window.location.origin + window.location.pathname;
    },
  },
  watch: {

  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    initGoogleLogin() {
			window.google.accounts.id.initialize({
        client_id: "219030855174-eb1an4vjkcjdou8rqd4bu2vlj7r3jrv8.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
				// login_uri: 'https://walkingcloset.network/closet/login_google',
				// ux_mode: "redirect"
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonRegDiv"),
        { type: 'icon', theme: "outline", size: "large" }  // customization attributes
      );
      // window.google.accounts.id.prompt();
		},
    handleCredentialResponse(response) {
      // console.log("Encoded JWT ID token: " + response.credential);
      this.loginByGoogleCredential(response.credential);
    },
    async loginByGoogleCredential(credential) {
      this.appendComponentBusy('GOOGLELOGIN');
      try {
        const {token} = await this.$store.dispatch('api/postGoogleLoginPromise', {credential});
        await this.$store.dispatch('login', token);
        await this.$router.push({name: 'Home'});
      } catch(e) {
        console.error(e);
        this.clearQuery();
        // this.$store.dispatch('logout');
      } finally {
        this.clearComponentBusy('GOOGLELOGIN');
      }
    },
    loginClicked() {
      this.backClicked();
    },
    backClicked() {
      this.$emit('close');
    },
    showPolicyModal() {
      this.$store.commit('CHANGEPOLICYMODAL', true);
    },
    async registerClicked() {
      const email = this.registerEmail.trim();
      const valid = await this.$store.dispatch('checkEmailValid', email);
      // console.log(valid);
      if (!valid) {
        this.$store.dispatch('showMsgModal', '請輸入正確的email格式');
        return;
      }

      const busyName = 'REGISTERBUSY';
      this.appendComponentBusy(busyName);
      try {
        await this.$store.dispatch('api/postRegisterByEmail', email);
        this.showMsgModal('註冊成功!!請至您註冊的 Email，依照指示步驟完成新密碼設定。');
        this.backClicked();
      } catch (e) {
        this.appendErrorMsg(e.toString());
      } finally {
        this.clearComponentBusy(busyName);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .register {
    padding: 2rem 1rem 2rem;
    h2 {
      color: var(--text-color-main);
      font-size: 1.25rem;
      margin-bottom: .5rem;
    }
    .register-div {
      text-align: center;
      margin-bottom: 1.3rem;
      >img {
        width: 40%;
      }
    }

    .bottom-sticky-login-div {
      position: fixed;
      left: 0;
      bottom: 0;
      background-color: #fff;
      width: 100%;
      padding: 1.5rem 1.5rem 2rem;
      box-shadow: 0 0 4px #0004;
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem;
      button {
        padding: .8rem 1.5rem;
        width: 100%;
        display: block;
        border: none;
        border-radius: 1rem;
        background: var(--brand-color-primary);
        color: #fff;
      }
    }

    .register-content {
      .register-with-email {
        padding: 0 1rem;
        margin-bottom: 2rem;
        .input-div {
          input {
            border: none;
            padding: .5rem 1rem;
            background-color: #eeeeee;
            // color: var(--text-color-remark);
            border-radius: 0.5rem;
            width: 100%;
            border-radius: .5rem;
            ::placeholder {
              color: var(--text-color-remark);
              color: red;
            }
          }
        }
        .forgot-psw {
          margin-bottom: 0;
          margin-top: .2rem;
          font-size: .8rem;
        }
        button {
          padding: 0.5rem 2.5rem;
          display: inline-block;
          border: none;
          border-radius: 1rem;
          background: var(--brand-color-primary);
          color: #fff;
        }
      }

      .login-with-sns {
        .sns-logins {
          display: flex;
          justify-content: center;
          padding: 2rem 1rem 0;
          column-gap: 1rem;
        }
        .sns-desc {
          position: relative;
          width: 100%;
          text-align: center;
          >span {
            position: relative;
            color: var(--text-color-main);
            font-size: .8rem;
            background-color: #ffffff;
            padding: 0 .25rem;
          }
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            top: 50%;
            border-top: solid 1px var(--border-color-main);
          }
        }

        .btn {
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.btn-wc {
            // font-weight: bold;
            // width: 100%;
            // border-radius: .5rem;
            // padding: .4rem 1rem .5em 1rem;
            // position: relative;
            >img {
              // position: absolute;
              // left: .5rem;
              // top: 50%;
              // transform: translate(0, -50%);
              // height: 24px;
            }
          }
          &.btn-line {
            background-color: #06C755;
            color: #fff;
            // text-align: left;
            // padding-left: 3rem;
            >img {
              height: 36px;
            }
          }

          &.btn-fb {
            background-color: #4676ED;
            color: #fff;
            // text-align: left;
            // padding-left: 3rem;
            >img {
              // left: .8rem;
              height: 24px;
            }
          }

          &.btn-desc {
            border-color: #F2C31C;
            width: 100%;
            display: block;
            border-radius: 999px;
            color: #F2C31C;
            letter-spacing: .15em;
            >img {
              height: 1em;
              display: inline-block;
              margin: 0 .2rem;
              position: relative;
              top: -0.2em;
            }
          }
        }

        .register-msg {
          text-align: center;
          color: var(--text-color-main);
          font-size: .8rem;
          margin-top: 1.5rem;
          margin-bottom: 0;
          >a {
            color: var(--text-color-main);
            text-decoration: underline;
          }
        }

        .agree-msg {
          margin-top: 1.1rem;
          text-align: center;
          color: var(--text-color-main);
          font-size: .8rem;
          >a {
            color: var(--text-color-main);
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
